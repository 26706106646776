// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import {
  Alert,
  Card,
  CircularProgress,
  Dialog,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MDInput from "components/MDInput";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import { ROLE_ACCESS } from "utils/constants/roles.constants";
import CompanyCard from "common/Cards/ProjectCards/companyCard";
import { getAllAnnouncements } from "api_services/OtherServices";
import { getNewMembers } from "api_services/MemberService";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import secureLocalStorage from "react-secure-storage";
import BannerCarousel from "./components/Banner/bannerCarousel";
import CollapsibleCard from "layouts/profile/users/member/collapsibleCard";
import { getListOfMembersFromId } from "api_services/MemberService";
import { searchAllBanners } from "api_services/BannerService";
import { getRelevantBanners } from "api_services/BannerService";
import { removeAnnouncement } from "api_services/OtherServices";
import { deleteBannerById } from "api_services/BannerService";
import { getPotentialPartnersOfMultipleMembers } from "api_services/MemberService";
// import { getImportingFrom } from "api_services/MemberService";
// import { getExportingTo } from "api_services/MemberService";
import flameIcon from "assets/images/fire.png";
import greenArrowIcon from "assets/images/green-arrow.png";
import blueArrowIcon from "assets/images/blue-arrow.png";
import FavouriteIcon from "common/Favourite";
import { getPotentialPartners } from "api_services/MemberService";
import PartnerCard from "common/Cards/ProjectCards/partnerCard";

export function addFavouriteMarker(memberList, userFavourites, idFieldName) {
  let isFavourite = false;
  for (let index = 0; index < memberList.length; index++) {
    const member = memberList[index];
    isFavourite = userFavourites.includes(member[idFieldName]);
    memberList[index] = {
      ...member,
      isFavourite: isFavourite,
    };
  }
}

function Dashboard() {
  const editor = JSON.parse(secureLocalStorage.getItem("current-user-data"))[
    ROLE_ACCESS.EDIT_ACCESS
  ];
  const userCompanies = JSON.parse(secureLocalStorage.getItem("linked-user-companies"));
  const userFavourites = JSON.parse(secureLocalStorage.getItem("user-favourites"));

  const [AllMemberData, setAllMemberData] = useState([]);
  const [AllFavouriteMemberData, setAllFavouriteMemberData] = useState([]);
  const userCompanyIds = JSON.parse(secureLocalStorage.getItem("current-user-data"))["companies"];
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    userCompanyIds.length > 0 ? userCompanyIds[0]["id"] : ""
  );
  const [banners, setBanners] = useState([]);
  const [partnerLoading, setPartnerLoading] = useState(false);
  const [marketOpportunities, setMarketOpportunities] = useState({
    data: [],
    nextPage: false,
    page: 0,
  });
  // const [importingFrom, setImportingFrom] = useState({
  //   data: [],
  //   nextPage: false,
  //   page: 0,
  // });
  // const [exportingTo, setExportingTo] = useState({
  //   data: [],
  //   nextPage: false,
  //   page: 0,
  // });
  const navigate = useNavigate();
  // const [memberAddress, setMemberAddress] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [deleteType, setDeleteType] = useState();
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const [isAnnLoading, setAnnIsLoading] = useState(true);
  const [isFavouriteLoading, setFavouriteIsLoading] = useState(true);
  const userToken = secureLocalStorage.getItem("custom-token");
  const [annID, setAnnID] = useState("");
  const [bannerID, setBannerID] = useState("");

  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [positionState, setPositionState] = useState({
    openTwo: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openTwo } = positionState;
  const handleAlertClose = () => {
    setPositionState({ ...positionState, openTwo: false });
  };

  const handleOpen = (id, type) => {
    setDeleteType(type);
    if (type === 0) {
      setAnnID(id);
    } else {
      setBannerID(id);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getSearchedMemberList();
  };

  const renderLinkedCompanyList = () => {
    return userCompanies?.map((data, index) => {
      const selected = selectedCompanyId === data.id;
      return (
        <MDBox
          id={index}
          borderRadius="lg"
          mr={1}
          mb={1}
          sx={{
            cursor: "pointer",
            // backgroundColor: selected ? "#1B2D0A" : "transparent",
            backgroundColor: selected ? "#9CE667" : "transparent",
            border: selected ? "1px solid transparent" : "1px solid lightGrey",
            transition: "background-color 300ms ease, border 300ms ease",
          }}
          onClick={() => {
            setSelectedCompanyId(data.id);
          }}
        >
          <MDTypography
            variant="h6"
            color={selected ? "blackOnLightBg" : "black"}
            fontWeight="regular"
            py={1}
            px={2}
          >
            {data.companyName} - {data.city}
          </MDTypography>
        </MDBox>
      );
    });
  };

  async function deleteAnnouncements() {
    setDeleting(true);
    const response = await removeAnnouncement(annID);

    if (response == "ok") {
      getTheAnnouncements();
      setOpen(false);
      setDeleting(false);
    } else {
      setOpen(false);
      setDeleting(false);
      setPositionState({ openTwo: true, vertical: "top", horizontal: "center" });
      //console.log(response);
    }
  }

  async function deleteBanner() {
    setDeleting(true);
    const response = await deleteBannerById(bannerID, userToken);

    if (response == "ok") {
      getBannerList();
      setOpen(false);
      setDeleting(false);
    } else {
      setOpen(false);
      setDeleting(false);
      setPositionState({ openTwo: true, vertical: "top", horizontal: "center" });
      //console.log(response);
    }
  }

  async function getBannerList() {
    let response;
    if (editor) {
      response = await searchAllBanners(userToken, 0, "", 5);
    } else {
      let cities = [];
      let countries = [];
      userCompanies.forEach((company) => {
        cities.push(company["city"]);
        countries.push(company["country"]);
      });
      response = await getRelevantBanners(userToken, 0, cities, countries, "FreytWorld", 100);
    }
    if (response !== null) {
      setIsBannerLoading(false);
      setBanners(response.searchResults);
    } else {
      setIsBannerLoading(true);
      //console.log(response);
    }
  }

  async function getPotentialPartnersList() {
    if (!editor) {
      setPartnerLoading(true);
      const response = await getPotentialPartners(userToken, 0, selectedCompanyId, 6, "");
      if (response !== null) {
        const members = response.searchResults;
        addFavouriteMarker(members, userFavourites, "memberId");
        setMarketOpportunities({
          data: members,
          nextPage: response.morePages,
          page: 0,
        });

        setPartnerLoading(false);
      } else {
        //console.log(response);
      }
    }
  }

  // async function getImportingFromLocations() {
  //   let response;
  //   if (!editor) {
  //     let countries = [];
  //     userCompanies.forEach((company) => {
  //       countries.push(company["country"]);
  //     });
  //     response = await getImportingFrom(userToken, 0, countries, 10);
  //   }
  //   if (response !== null) {
  //     const members = response.searchResults;
  //     if (!editor) {
  //       addFavouriteMarker(members, userFavourites, "id");
  //     }
  //     setImportingFrom({
  //       data: members,
  //       nextPage: response.morePages,
  //       page: 0,
  //     });
  //   } else {
  //     //console.log(response);
  //   }
  // }

  // async function getExportingToLocations() {
  //   let response;
  //   if (!editor) {
  //     let countries = [];
  //     userCompanies.forEach((company) => {
  //       countries.push(company["country"]);
  //     });
  //     response = await getExportingTo(userToken, 0, countries, 10);
  //   }
  //   if (response !== null) {
  //     const members = response.searchResults;
  //     if (!editor) {
  //       addFavouriteMarker(members, userFavourites, "id");
  //     }
  //     setExportingTo({
  //       data: members,
  //       nextPage: response.morePages,
  //       page: 0,
  //     });
  //   } else {
  //     //console.log(response);
  //   }
  // }

  async function getNewMemberList() {
    const response = await getNewMembers(userToken, 0);
    if (response !== null) {
      const newMembers = response.searchResults;
      if (!editor) {
        addFavouriteMarker(newMembers, userFavourites, "id");
      }
      setAllMemberData(newMembers);
    } else {
      //console.log(response);
    }
  }

  function getSearchedMemberList() {
    navigate(`/directory/search/${searchData}`);
  }

  async function getAllFavouriteMemberList() {
    if (!editor) {
      const response = await getListOfMembersFromId(userFavourites.slice(0, 6), userToken);
      const favMembers = response;
      for (let index = 0; index < favMembers.length; index++) {
        const member = favMembers[index];
        favMembers[index] = {
          ...member,
          isFavourite: true,
        };
      }
      if (response !== null) {
        setFavouriteIsLoading(false);
        setAllFavouriteMemberData(favMembers);
        // setMemberAddress(response.address[0]);
      } else {
        setFavouriteIsLoading(true);
        //console.log(response);
      }
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getBannerList();
    // getImportingFromLocations();
    // getExportingToLocations();
    getTheAnnouncements();
    getNewMemberList();
    getAllFavouriteMemberList();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getPotentialPartnersList();
  }, [selectedCompanyId]);

  const [announcements, setAnnouncements] = useState([]);

  async function getTheAnnouncements() {
    const response = await getAllAnnouncements(3);

    if (response !== null) {
      setAnnIsLoading(false);
      setAnnouncements(response);
    } else {
      setAnnIsLoading(true);
      //console.log(response);
    }
  }

  //////////////////////////////////////

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const renderPotentialPartnerList = marketOpportunities?.data.map(
    ({ id, companyName, matchCategory, address, isFavourite, memberId, priority }) => (
      <Grid item sm={12} md={12} lg={12} xl={12} xxl={6} key={id} sx={{ width: "100%" }}>
        <PartnerCard
          id={id}
          companyName={companyName}
          matchCategory={matchCategory}
          city={address.length > 0 ? address[0].city : ""}
          country={address.length > 0 ? address[0].country : ""}
          isFavourite={isFavourite}
          refresh={true}
          memberId={memberId}
          priority={priority}
          action={{
            type: "internal",
            route: `/directory/profile/${memberId}`,
          }}
        />
      </Grid>
    )
  );

  const renderPotentialPartnerTable = marketOpportunities?.data.map(
    ({ id, companyName, matchCategory, address, isFavourite, memberId, priority }) => (
      <TableRow key={id}>
        <TableCell>
          <img
            height="20px"
            src={
              matchCategory === "mutual_interest"
                ? flameIcon
                : matchCategory === "partner_interest"
                  ? blueArrowIcon
                  : matchCategory === "member_interest"
                    ? greenArrowIcon
                    : ""
            }
            title={
              matchCategory === "mutual_interest"
                ? "Perfect Match"
                : matchCategory === "partner_interest"
                  ? "Have Shown Interest in Your Location"
                  : matchCategory === "member_interest"
                    ? "Located in a Region You Are Interested In"
                    : ""
            }
            alt="Partner Interest icon"
            style={{ cursor: "help" }}
          />
        </TableCell>
        <TableCell>
          <FavouriteIcon isFavourite={isFavourite} memberId={memberId} refresh={true} />
        </TableCell>
        <TableCell>
          <MDBox>
            <MDTypography
              variant="button"
              fontWeight="small"
              color={priority === 4 ? "grey[700]" : "black"}
            >
              {companyName}
            </MDTypography>
          </MDBox>
        </TableCell>
        <TableCell>
          <MDBox>
            <MDTypography
              variant="button"
              fontWeight="small"
              color={priority === 4 ? "grey[700]" : "black"}
            >
              {address.length > 0 ? address[0].country : ""}
            </MDTypography>
          </MDBox>
        </TableCell>
        <TableCell>
          <MDBox>
            <MDTypography
              variant="button"
              fontWeight="small"
              color={priority === 4 ? "grey[700]" : "black"}
            >
              {address.length > 0 ? address[0].city : ""}
            </MDTypography>
          </MDBox>
        </TableCell>
        <TableCell>
          <MDBox display="flex" justifyContent="center">
            <MDBox
              borderRadius="lg"
              py={1}
              px={2}
              sx={{
                cursor: "pointer",
                backgroundColor: priority === 4 ? "transparent" : "#9CE667",
                width: "fit-content",
              }}
            >
              <MDTypography
                variant="h6"
                color={priority === 4 ? "lightGray" : "blackOnLightBg"}
                fontWeight="regular"
                //component="a"
                component={Link}
                to={`/directory/profile/${memberId}`}
                //href={`/directory/profile/${memberId}`}
                target="_blank"
              >
                View Profile
              </MDTypography>
            </MDBox>
          </MDBox>
        </TableCell>
      </TableRow>
    )
  );

  const renderMemberProfiles = (memberList, idFieldName) => {
    return memberList?.map((data, index) => {
      return (
        <Grid item sm={12} md={12} lg={12} xl={6} xxl={6} key={data.id}>
          <CompanyCard
            isEditor={editor}
            id={data[idFieldName]}
            isHeadOffice={data.isHeadOffice == "true"}
            image={data.companyLogo}
            label={data.address?.map((add) => add.city + ", " + add.country)}
            description={data.companyProfile}
            title={data.companyName}
            linkedin={data.linkedInUrl}
            action={{
              type: "internal",
              route: `/directory/profile/${data.id}`,
            }}
            authors={data.memberContacts}
            isFavourite={data.isFavourite}
            refresh={true}
          />
        </Grid>
      );
    });
  };

  const Banners = banners.map((banner) => {
    const currentDate = new Date();
    let displayDate;
    let displayDateText;
    let published = false;
    let expired = false;
    let scheduled = false;
    if (banner.dateToPublish <= currentDate.getTime()) {
      if (banner.expiryDate < currentDate.getTime()) {
        expired = true;
        displayDate = banner.expiryDate;
        displayDateText = "Expired On ";
      } else {
        published = true;
        displayDate = banner.expiryDate;
        displayDateText = "Expiring On ";
      }
    } else {
      scheduled = true;
      displayDate = banner.dateToPublish;
      displayDateText = "To Be Published On ";
    }
    return (
      <Card sx={{ mx: 0, mb: 2 }} key={banner.id}>
        <MDBox
          display="flex"
          alignItems="center"
          p={2}
          sx={{
            mb: { xs: -4, md: -4, lg: -7 },
            mr: { xs: 3, md: 0 },
            justifyContent: { xs: "space-between", lg: "end" },
          }}
        >
          <MDTypography variant="caption" color="text" ml={0} mr={10}>
            {displayDateText +
              new Date(displayDate).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
          </MDTypography>

          {editor ? (
            <MDBox display="flex" alignItems="center">
              <EditIcon
                color="black"
                fontSize="small"
                sx={{ cursor: "pointer", right: 53, position: "absolute" }}
                onClick={() => navigate(`/update-banner/${banner.id}`)}
              />
              <DeleteIcon
                color="error"
                fontSize="medium"
                sx={{ cursor: "pointer", mr: 2, right: 0, position: "absolute" }}
                onClick={() => {
                  handleOpen(banner.id, 1);
                }}
              />
            </MDBox>
          ) : null}
        </MDBox>
        <MDBox
          py={2}
          pl={1}
          pr={2}
          mt={1}
          display="flex"
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "start", md: "center" },
          }}
        >
          <Grid item ml={1} xs={12} md={2}>
            <img
              width="100%"
              height="100%"
              src={banner.bannerImage}
              style={{ borderRadius: "17px", objectFit: "cover" }}
            />
          </Grid>
          <MDBox ml={2}>
            <MDBox display="flex" flexDirection="column">
              <MDBox display="flex">
                <MDTypography variant="button" fontWeight="medium" color="black" mt={0}>
                  {banner.title}
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color={scheduled ? "grey[700]" : expired ? "error" : "primary"}
                  mt={0}
                  ml={2}
                >
                  {scheduled ? "SCHEDULED" : expired ? "EXPIRED" : published ? "PUBLISHED" : null}
                </MDTypography>
              </MDBox>
              {/* <MDTypography variant="caption" color="black" fontWeight="none" mt={0.5}>
                {banner.description}
              </MDTypography> */}
              <MDBox
                display="flex"
                justifyContent="start"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "start", md: "center" },
                }}
              >
                {banner.cities.map((name) => (
                  <MDBadge
                    badgeContent={name}
                    color="info"
                    variant="gradient"
                    size="sm"
                    sx={{
                      ml: { xs: -1, md: 0 },
                      my: { xs: 1, md: 1 },
                    }}
                  />
                ))}
                {banner.countries.map((name) => (
                  <MDBadge
                    badgeContent={name}
                    color="primaryDark"
                    variant="gradient"
                    size="sm"
                    sx={{
                      ml: { xs: -1, md: 0 },
                      my: { xs: 1, md: 1 },
                    }}
                  />
                ))}
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    );
  });

  const Announcements = announcements.map((ann) => (
    <Card sx={{ mx: 0, mb: 2 }} key={ann.id}>
      <MDBox
        display="flex"
        alignItems="center"
        p={2}
        sx={{
          mb: { xs: -4, md: -4, lg: -7 },
          mr: { xs: 3, md: 0 },
          justifyContent: { xs: "space-between", lg: "end" },
        }}
      >
        <MDTypography variant="caption" color="text" ml={0} mr={10}>
          {new Date(ann.publishedAt).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        </MDTypography>

        {editor ? (
          <MDBox display="flex" alignItems="center">
            <EditIcon
              color="black"
              fontSize="small"
              sx={{ cursor: "pointer", right: 53, position: "absolute" }}
              onClick={() => navigate(`/update-announcement/${ann.id}`)}
            />
            <DeleteIcon
              color="error"
              fontSize="medium"
              sx={{ cursor: "pointer", mr: 2, right: 0, position: "absolute" }}
              onClick={() => handleOpen(ann.id, 0)}
            />
          </MDBox>
        ) : null}
      </MDBox>
      <MDBox
        py={2}
        pl={1}
        pr={2}
        display="flex"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "start", md: "center" },
        }}
      >
        {/* <MDBox mr={3}>
  <MDAvatar
    src={image}
    alt="something here"
    shadow="md"
    size="xl"
    variant="square"
    sx={{ borderRadius: 3 }}
  />
</MDBox> */}
        <MDBox ml={1}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "start", md: "center" },
            }}
          >
            <MDBox
              display="flex"
              justifyContent="start"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "start", md: "center" },
              }}
            >
              <MDTypography
                variant="caption"
                fontWeight="none"
                color="black"
                sx={{
                  mt: { xs: 1, md: 0 },
                  mr: 2,
                }}
              >
                {ann.posted_by}
              </MDTypography>

              {ann.labels.map((name) => (
                <MDBadge
                  badgeContent={name}
                  color={
                    name == "Conference Update"
                      ? "info"
                      : name == "Member Announcement"
                        ? "primaryDark"
                        : name == "Member News"
                          ? "primary"
                          : name == "Alert"
                            ? "error"
                            : name == "Network News"
                              ? "infoBright"
                              : ""
                  }
                  variant="gradient"
                  size="sm"
                  sx={{
                    ml: { xs: -1, md: 0 },
                    my: { xs: 1, md: 1 },
                  }}
                />
              ))}
            </MDBox>
          </MDBox>

          <MDBox display="flex" flexDirection="column">
            {ann.link !== null && ann.link.length > 0 ? (
              <MDTypography
                variant="button"
                fontWeight="medium"
                color="black"
                mt={0}
                component="a"
                href={ann.link}
                target="_blank"
              >
                {ann.title}
              </MDTypography>
            ) : (
              <MDTypography variant="button" fontWeight="medium" color="black" mt={0}>
                {ann.title}
              </MDTypography>
            )}
            <MDTypography variant="caption" color="black" fontWeight="none" mt={0.5}>
              {ann.description}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  ));

  const favouriteCompanies = AllFavouriteMemberData.slice(0, 4).map((data, index) => {
    return (
      <Grid item sm={12} md={12} lg={12} xl={6} xxl={6} key={data.id}>
        <CompanyCard
          isEditor={editor}
          id={data.id}
          image={data.companyLogo}
          isHeadOffice={data.isHeadOffice == "true"}
          label={data.address?.map((add) => add.city + ", " + add.country)}
          description={data.companyProfile}
          title={data.companyName}
          linkedin={data.linkedInUrl}
          action={{
            type: "internal",
            route: `/directory/profile/${data.id}`,
          }}
          authors={data.memberContacts}
          isFavourite={data.isFavourite}
          refresh={true}
        />
      </Grid>
    );
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        {isAnnLoading || isLoading ? (
          <Stack spacing={3}>
            <MDBox display="flex" mt={2}>
              <Skeleton variant="circular" width={120} height={100} sx={{ mr: 3 }} />
              <Skeleton variant="rectangular" width={"100%"} height={100} />
            </MDBox>
            <MDBox display="flex">
              <Skeleton variant="circular" width={120} height={100} sx={{ mr: 3 }} />
              <Skeleton variant="rectangular" width={"100%"} height={100} />
            </MDBox>
            <Skeleton variant="rectangular" width={"100%"} height={70} />
            <Skeleton variant="rectangular" width={"100%"} height={40} />
            <Skeleton variant="rectangular" width={"100%"} height={20} />
          </Stack>
        ) : (
          <>
            <MDBox
              sx={{
                display: { xs: "none", sm: "none", md: "grid" },
              }}
            >
              <Grid container display="grid" spacing={2}>
                {isBannerLoading ? (
                  <Stack spacing={2} m={3}>
                    <Skeleton variant="rectangular" width={"100%"} height={50} />
                    <Skeleton variant="rectangular" width={"100%"} height={20} />
                    <Skeleton variant="rectangular" width={"100%"} height={10} />
                  </Stack>
                ) : editor ? null : (
                  <Grid item>
                    <BannerCarousel banners={banners} />
                  </Grid>
                )}
                <Grid item>
                  <CollapsibleCard
                    height="auto"
                    title={
                      <MDTypography variant="h5" fontWeight="medium" color="black">
                        Member Search
                      </MDTypography>
                    }
                    expanded="true"
                  >
                    <MDBox
                      mb={2}
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                      component="form"
                      role="form"
                      onSubmit={handleSubmit}
                    >
                      <MDInput
                        sx={{
                          mr: { xs: 0, md: 2 },
                          mb: { md: 0, xs: 1 },
                          pl: 0,
                          flex: 1,
                          width: "100%",
                        }}
                        placeholder="Search by : Company name | Country | City | User"
                        onChange={(e) => setSearchData(e.target.value)}
                      />
                      <MDBox
                        sx={{
                          display: { xs: "none", sm: "none", md: "grid" },
                        }}
                      >
                        <MDButton size="medium" color="primary" type="submit">
                          Search
                        </MDButton>
                      </MDBox>
                      <MDBox
                        sx={{
                          display: { xs: "contents", sm: "contents", md: "none" },
                        }}
                      >
                        <MDButton
                          style={{ width: "100%" }}
                          size="medium"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </CollapsibleCard>
                </Grid>
                {/* {editor ? (
                  <Grid item>
                    <CollapsibleCard
                      height="auto"
                      title={
                        <MDBox
                          display="flex"
                          sx={{ flexDirection: { xs: "column", md: "row" } }}
                          justifyContent="space-between"
                          width="100%"
                        >
                          <MDTypography variant="h5" fontWeight="medium" color="black">
                            Banners
                          </MDTypography>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="black"
                            sx={{ cursor: "pointer", textDecoration: "underline" }}
                            onClick={() => navigate("/banners")}
                          >
                            View All Banners
                          </MDTypography>
                        </MDBox>
                      }
                      expanded={true}
                    >
                      {Banners.length !== 0 ? (
                        <Grid container>
                          <Grid item xs={12} md={12} xl={12}>
                            {Banners}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid mt={3} mb={2}>
                          <MDTypography
                            variant="text"
                            color="text"
                            display="flex"
                            justifyContent="center"
                            mb={2}
                          >
                            No Banners found !
                          </MDTypography>
                        </Grid>
                      )}
                    </CollapsibleCard>
                  </Grid>
                ) : null} */}
                <Grid item>
                  <CollapsibleCard
                    height="auto"
                    title={
                      <MDBox
                        display="flex"
                        sx={{ flexDirection: { xs: "column", md: "row" } }}
                        justifyContent="space-between"
                        width="100%"
                      >
                        <MDTypography variant="h5" fontWeight="medium" color="black">
                          Announcements
                        </MDTypography>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="black"
                          sx={{ cursor: "pointer", textDecoration: "underline" }}
                          onClick={() => navigate("/announcements")}
                        >
                          View All Announcements
                        </MDTypography>
                      </MDBox>
                    }
                    expanded={true}
                  >
                    {Announcements.length !== 0 ? (
                      <Grid container>
                        <Grid item xs={12} md={12} xl={12}>
                          {Announcements}
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid mt={3} mb={2}>
                        <MDTypography
                          variant="text"
                          color="text"
                          display="flex"
                          justifyContent="center"
                          mb={2}
                        >
                          No Announcements found !
                        </MDTypography>
                      </Grid>
                    )}
                  </CollapsibleCard>
                </Grid>
                <Grid item>
                  <CollapsibleCard
                    height="auto"
                    title={
                      <MDBox
                        display="flex"
                        sx={{ flexDirection: { xs: "column", md: "row" } }}
                        justifyContent="space-between"
                        width="100%"
                      >
                        <MDTypography variant="h5" fontWeight="medium" color="black">
                          New Members
                        </MDTypography>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="black"
                          sx={{ cursor: "pointer", textDecoration: "underline" }}
                          onClick={() => navigate("/directory")}
                        >
                          View All Members
                        </MDTypography>
                      </MDBox>
                    }
                    expanded={true}
                  >
                    {AllMemberData.length !== 0 ? (
                      <Grid container spacing={3} pb={2}>
                        {renderMemberProfiles(AllMemberData, "id")}
                      </Grid>
                    ) : (
                      <Grid mt={3} mb={2}>
                        <MDTypography
                          variant="text"
                          color="text"
                          display="flex"
                          justifyContent="center"
                          mb={2}
                        >
                          No New Companies found !
                        </MDTypography>
                      </Grid>
                    )}
                  </CollapsibleCard>
                </Grid>
                {!editor ? (
                  <>
                    <Grid item>
                      <CollapsibleCard
                        height="auto"
                        title={
                          <MDBox
                            display="flex"
                            sx={{ flexDirection: { xs: "column", md: "row" } }}
                            justifyContent="space-between"
                            width="100%"
                          >
                            <MDTypography variant="h5" fontWeight="medium" color="black">
                              Potential Partners
                            </MDTypography>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="black"
                              sx={{ cursor: "pointer", textDecoration: "underline" }}
                              onClick={() => navigate("/potential-partners")}
                            >
                              View All Potential Partners
                            </MDTypography>
                          </MDBox>
                        }
                        expanded={true}
                      >
                        <>
                          <MDBox
                            display="flex"
                            mb={1}
                            sx={{
                              flexDirection: { xs: "column", md: "row" },
                              maxWidth: "3000px",
                              flexWrap: "wrap",
                            }}
                          >
                            {renderLinkedCompanyList()}
                          </MDBox>
                          {partnerLoading ? (
                            <MDBox display="flex" justifyContent="center" m={2}>
                              <CircularProgress />
                            </MDBox>
                          ) : marketOpportunities.data.length !== 0 ? (
                            <MDBox pt={1} pb={2}>
                              <TableContainer
                                fullWidth
                                sx={{ px: 2, boxShadow: "0px 0px 0px white" }}
                              >
                                <Table sx={{ minWidth: 800 }} aria-label="simple table" fullWidth>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>
                                        <MDTypography variant="button" color="transparent">
                                          -
                                        </MDTypography>
                                      </TableCell>
                                      <TableCell>
                                        <MDTypography variant="button" color="text">
                                          Favourite
                                        </MDTypography>
                                      </TableCell>
                                      <TableCell>
                                        <MDTypography variant="button" color="text">
                                          Company Name
                                        </MDTypography>
                                      </TableCell>
                                      <TableCell>
                                        <MDTypography variant="button" color="text">
                                          Country
                                        </MDTypography>
                                      </TableCell>
                                      <TableCell>
                                        <MDTypography variant="button" color="text">
                                          City
                                        </MDTypography>
                                      </TableCell>
                                      <TableCell>
                                        <MDTypography variant="button" color="transparent">
                                          -
                                        </MDTypography>
                                      </TableCell>
                                    </TableRow>
                                    {renderPotentialPartnerTable}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </MDBox>
                          ) : (
                            <Grid mt={3} mb={2}>
                              <MDTypography
                                variant="text"
                                color="text"
                                display="flex"
                                justifyContent="center"
                                mb={2}
                              >
                                No Potential Partners found
                              </MDTypography>
                            </Grid>
                          )}
                        </>
                      </CollapsibleCard>
                    </Grid>
                    <Grid item>
                      <CollapsibleCard
                        height="auto"
                        title={
                          <MDBox
                            display="flex"
                            sx={{ flexDirection: { xs: "column", md: "row" } }}
                            justifyContent="space-between"
                            width="100%"
                          >
                            <MDTypography variant="h5" fontWeight="medium" color="black">
                              Favourite Companies
                            </MDTypography>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="black"
                              sx={{ cursor: "pointer", textDecoration: "underline" }}
                              onClick={() => navigate("/favourites")}
                            >
                              View All Favourites
                            </MDTypography>
                          </MDBox>
                        }
                        expanded={true}
                      >
                        {isFavouriteLoading ? (
                          // Render a spinner or loading indicator
                          <Stack spacing={2}>
                            <Grid container display="flex" justifyContent="space-around">
                              <Grid xs={12} sm={5.5} md={5.5} xl={5.5}>
                                <MDBox display="flex">
                                  <Skeleton
                                    variant="circular"
                                    width={120}
                                    height={90}
                                    sx={{ mr: 3 }}
                                  />
                                  <Skeleton variant="rectangular" width={"100%"} height={90} />
                                </MDBox>
                              </Grid>
                              <Grid xs={12} sm={5.5} md={5.5} xl={5.5}>
                                <MDBox display="flex">
                                  <Skeleton
                                    variant="circular"
                                    width={120}
                                    height={90}
                                    sx={{ mr: 3 }}
                                  />
                                  <Skeleton variant="rectangular" width={"100%"} height={90} />
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Skeleton variant="rectangular" width={"100%"} height={50} />
                            <Skeleton variant="rectangular" width={"100%"} height={20} />
                            <Skeleton variant="rectangular" width={"100%"} height={10} />
                          </Stack>
                        ) : (
                          <>
                            {AllFavouriteMemberData.length !== 0 ? (
                              <Grid container spacing={3} pb={2}>
                                {favouriteCompanies}
                              </Grid>
                            ) : (
                              <Grid mt={3} mb={2}>
                                <MDTypography
                                  variant="text"
                                  color="text"
                                  display="flex"
                                  justifyContent="center"
                                  mb={2}
                                >
                                  No Favourite Companies !
                                </MDTypography>
                              </Grid>
                            )}
                          </>
                        )}
                      </CollapsibleCard>
                    </Grid>
                    {/* <Grid item>
                      <CollapsibleCard
                        height="auto"
                        title={
                          <MDTypography variant="h5" fontWeight="medium" color="black">
                            Importing From Your Locations
                          </MDTypography>
                        }
                        expanded={true}
                      >
                        {importingFrom.data.length !== 0 ? (
                          <Grid container spacing={3} pb={2}>
                            {renderMemberProfiles(importingFrom.data, "id")}
                          </Grid>
                        ) : (
                          <Grid mt={3} mb={2}>
                            <MDTypography
                              variant="text"
                              color="text"
                              display="flex"
                              justifyContent="center"
                              mb={2}
                            >
                              No Companies !
                            </MDTypography>
                          </Grid>
                        )}
                      </CollapsibleCard>
                    </Grid>
                    <Grid item>
                      <CollapsibleCard
                        height="auto"
                        title={
                          <MDTypography variant="h5" fontWeight="medium" color="black">
                            Exporting To Your Locations
                          </MDTypography>
                        }
                        expanded={true}
                      >
                        {exportingTo.data.length !== 0 ? (
                          <Grid container spacing={3} pb={2}>
                            {renderMemberProfiles(exportingTo.data, "id")}
                          </Grid>
                        ) : (
                          <Grid mt={3} mb={2}>
                            <MDTypography
                              variant="text"
                              color="text"
                              display="flex"
                              justifyContent="center"
                              mb={2}
                            >
                              No Companies !
                            </MDTypography>
                          </Grid>
                        )}
                      </CollapsibleCard>
                    </Grid> */}
                  </>
                ) : null}
              </Grid>
            </MDBox>
            <MDBox
              sx={{
                display: { xs: "grid", sm: "grid", md: "none" },
              }}
            >
              <Grid container display="grid" spacing={2}>
                {/* {isBannerLoading ? (
                  <Stack spacing={2} m={3}>
                    <Skeleton variant="rectangular" width={"100%"} height={50} />
                    <Skeleton variant="rectangular" width={"100%"} height={20} />
                    <Skeleton variant="rectangular" width={"100%"} height={10} />
                  </Stack>
                ) : editor ? null : (
                  <Grid item>
                    <BannerCarousel banners={banners} />
                  </Grid>
                )} */}
                <Grid item>
                  <CollapsibleCard
                    height="auto"
                    title={
                      <MDTypography variant="h5" fontWeight="medium" color="black">
                        Member Search
                      </MDTypography>
                    }
                    expanded={true}
                  >
                    <MDBox
                      mb={2}
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                      component="form"
                      role="form"
                      onSubmit={handleSubmit}
                    >
                      <MDInput
                        sx={{
                          mr: { xs: 0, md: 2 },
                          mb: { md: 0, xs: 1 },
                          pl: 0,
                          flex: 1,
                          width: "100%",
                        }}
                        placeholder="Search by : Company name | Country | City | User"
                        onChange={(e) => setSearchData(e.target.value)}
                      />
                      <MDBox
                        sx={{
                          display: { xs: "none", sm: "none", md: "grid" },
                        }}
                      >
                        <MDButton size="medium" color="primary" type="submit">
                          Search
                        </MDButton>
                      </MDBox>
                      <MDBox
                        sx={{
                          display: { xs: "contents", sm: "contents", md: "none" },
                        }}
                      >
                        <MDButton
                          style={{ width: "100%" }}
                          size="medium"
                          color="primary"
                          type="submit"
                        >
                          Search
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </CollapsibleCard>
                </Grid>
                {/* {editor ? (
                  <Grid item>
                    <CollapsibleCard
                      height="auto"
                      title={
                        <MDBox
                          display="flex"
                          sx={{ flexDirection: { xs: "column", md: "row" } }}
                          justifyContent="space-between"
                          width="100%"
                        >
                          <MDTypography variant="h5" fontWeight="medium" color="black">
                            Banners
                          </MDTypography>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="black"
                            sx={{ cursor: "pointer", textDecoration: "underline" }}
                            onClick={() => navigate("/banners")}
                          >
                            View All Banners
                          </MDTypography>
                        </MDBox>
                      }
                      canExpand={true}
                    >
                      {Banners.length !== 0 ? (
                        <Grid container>
                          <Grid item xs={12} md={12} xl={12}>
                            {Banners}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid mt={3} mb={2}>
                          <MDTypography
                            variant="text"
                            color="text"
                            display="flex"
                            justifyContent="center"
                            mb={2}
                          >
                            No Banners found !
                          </MDTypography>
                        </Grid>
                      )}
                    </CollapsibleCard>
                  </Grid>
                ) : null} */}

                <Grid item>
                  <CollapsibleCard
                    height="auto"
                    title={
                      <MDBox
                        display="flex"
                        sx={{ flexDirection: { xs: "column", md: "row" } }}
                        justifyContent="space-between"
                        width="100%"
                      >
                        <MDTypography variant="h5" fontWeight="medium" color="black">
                          Announcements
                        </MDTypography>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="black"
                          sx={{ cursor: "pointer", textDecoration: "underline" }}
                          onClick={() => navigate("/announcements")}
                        >
                          View All Announcements
                        </MDTypography>
                      </MDBox>
                    }
                    canExpand={true}
                  >
                    {Announcements.length !== 0 ? (
                      <Grid container>
                        <Grid item xs={12} md={12} xl={12}>
                          {Announcements}
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid mt={3} mb={2}>
                        <MDTypography
                          variant="text"
                          color="text"
                          display="flex"
                          justifyContent="center"
                          mb={2}
                        >
                          No Announcements found !
                        </MDTypography>
                      </Grid>
                    )}
                  </CollapsibleCard>
                </Grid>
                <Grid item>
                  <CollapsibleCard
                    height="auto"
                    title={
                      <MDBox
                        display="flex"
                        sx={{ flexDirection: { xs: "column", md: "row" } }}
                        justifyContent="space-between"
                        width="100%"
                      >
                        <MDTypography variant="h5" fontWeight="medium" color="black">
                          New Members
                        </MDTypography>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="black"
                          sx={{ cursor: "pointer", textDecoration: "underline" }}
                          onClick={() => navigate("/directory")}
                        >
                          View All Members
                        </MDTypography>
                      </MDBox>
                    }
                    canExpand={true}
                  >
                    {AllMemberData.length !== 0 ? (
                      <>
                        <Grid container spacing={3} pb={2}>
                          {renderMemberProfiles(AllMemberData, "id")}
                        </Grid>
                      </>
                    ) : (
                      <Grid mt={3} mb={2}>
                        <MDTypography
                          variant="text"
                          color="text"
                          display="flex"
                          justifyContent="center"
                          mb={2}
                        >
                          No New Companies found !
                        </MDTypography>
                      </Grid>
                    )}
                  </CollapsibleCard>
                </Grid>
                {!editor ? (
                  <>
                    <Grid item xs={12} md={12} xl={12} style={{ width: `calc(100vw - 16px)` }}>
                      <CollapsibleCard
                        height="auto"
                        title={
                          <MDBox
                            display="flex"
                            sx={{ flexDirection: { xs: "column", md: "row" } }}
                            justifyContent="space-between"
                            width="100%"
                          >
                            <MDTypography variant="h5" fontWeight="medium" color="black">
                              Potential Partners
                            </MDTypography>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="black"
                              sx={{ cursor: "pointer", textDecoration: "underline" }}
                              onClick={() => navigate("/potential-partners")}
                            >
                              View All Potential Partners
                            </MDTypography>
                          </MDBox>
                        }
                        canExpand={true}
                      >
                        <MDBox
                          display="flex"
                          mb={1}
                          sx={{
                            flexDirection: { xs: "column", md: "row" },
                            maxWidth: "3000px",
                            flexWrap: "wrap",
                          }}
                        >
                          {renderLinkedCompanyList()}
                        </MDBox>

                        {partnerLoading ? (
                          <MDBox display="flex" justifyContent="center" m={2}>
                            <CircularProgress />
                          </MDBox>
                        ) : marketOpportunities.data.length !== 0 ? (
                          <Grid container spacing={3} pb={2}>
                            {renderPotentialPartnerList}
                            {/* {renderMemberProfiles(AllMemberData, "id")} */}
                          </Grid>
                        ) : (
                          <Grid mt={3} mb={2}>
                            <MDTypography
                              variant="text"
                              color="text"
                              display="flex"
                              justifyContent="center"
                              mb={2}
                            >
                              No Potential Partners found
                            </MDTypography>
                          </Grid>
                        )}
                      </CollapsibleCard>
                    </Grid>
                    <Grid item>
                      <CollapsibleCard
                        height="auto"
                        title={
                          <MDBox
                            display="flex"
                            sx={{ flexDirection: { xs: "column", md: "row" } }}
                            justifyContent="space-between"
                            width="100%"
                          >
                            <MDTypography variant="h5" fontWeight="medium" color="black">
                              Favourite Companies
                            </MDTypography>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="black"
                              sx={{ cursor: "pointer", textDecoration: "underline" }}
                              onClick={() => navigate("/favourites")}
                            >
                              View All Favourites
                            </MDTypography>
                          </MDBox>
                        }
                        canExpand={true}
                      >
                        {isFavouriteLoading ? (
                          // Render a spinner or loading indicator
                          <Stack spacing={2}>
                            <Grid container display="flex" justifyContent="space-around">
                              <Grid xs={12} sm={5.5} md={5.5} xl={5.5}>
                                <MDBox display="flex">
                                  <Skeleton
                                    variant="circular"
                                    width={120}
                                    height={90}
                                    sx={{ mr: 3 }}
                                  />
                                  <Skeleton variant="rectangular" width={"100%"} height={90} />
                                </MDBox>
                              </Grid>
                              <Grid xs={12} sm={5.5} md={5.5} xl={5.5}>
                                <MDBox display="flex">
                                  <Skeleton
                                    variant="circular"
                                    width={120}
                                    height={90}
                                    sx={{ mr: 3 }}
                                  />
                                  <Skeleton variant="rectangular" width={"100%"} height={90} />
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Skeleton variant="rectangular" width={"100%"} height={50} />
                            <Skeleton variant="rectangular" width={"100%"} height={20} />
                            <Skeleton variant="rectangular" width={"100%"} height={10} />
                          </Stack>
                        ) : (
                          <>
                            {AllFavouriteMemberData.length !== 0 ? (
                              <Grid container spacing={3} pb={2}>
                                {favouriteCompanies}
                              </Grid>
                            ) : (
                              <Grid mt={3} mb={2}>
                                <MDTypography
                                  variant="text"
                                  color="text"
                                  display="flex"
                                  justifyContent="center"
                                  mb={2}
                                >
                                  No Favourite Companies !
                                </MDTypography>
                              </Grid>
                            )}
                          </>
                        )}
                      </CollapsibleCard>
                    </Grid>
                    {/* <Grid item>
                      <CollapsibleCard
                        height="auto"
                        title={
                          <MDTypography variant="h5" fontWeight="medium" color="black">
                            Importing From Your Locations
                          </MDTypography>
                        }
                        canExpand={true}
                      >
                        {importingFrom.data.length !== 0 ? (
                          <Grid container spacing={3} pb={2}>
                            {renderMemberProfiles(importingFrom.data, "id")}
                          </Grid>
                        ) : (
                          <Grid mt={3} mb={2}>
                            <MDTypography
                              variant="text"
                              color="text"
                              display="flex"
                              justifyContent="center"
                              mb={2}
                            >
                              No Companies !
                            </MDTypography>
                          </Grid>
                        )}
                      </CollapsibleCard>
                    </Grid>
                    <Grid item>
                      <CollapsibleCard
                        height="auto"
                        title={
                          <MDTypography variant="h5" fontWeight="medium" color="black">
                            Exporting To Your Locations
                          </MDTypography>
                        }
                        canExpand={true}
                      >
                        {exportingTo.data.length !== 0 ? (
                          <Grid container spacing={3} pb={2}>
                            {renderMemberProfiles(exportingTo.data, "id")}
                          </Grid>
                        ) : (
                          <Grid mt={3} mb={2}>
                            <MDTypography
                              variant="text"
                              color="text"
                              display="flex"
                              justifyContent="center"
                              mb={2}
                            >
                              No Companies !
                            </MDTypography>
                          </Grid>
                        )}
                      </CollapsibleCard>
                    </Grid> */}
                  </>
                ) : null}
              </Grid>
            </MDBox>
          </>
        )}
      </div>

      <MDBox sx={{ overflowY: "visible" }}>
        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflowY: "visible" }}
        >
          <MDBox mb={1} mt={5} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
            <MDTypography display="block" variant="button" color="primaryDark">
              Are you sure you want to remove this item?
            </MDTypography>
            {deleting && <CircularProgress sx={{ mt: 2 }} />}
          </MDBox>
          <MDBox mt={2} mb={4} display="flex" justifyContent="center">
            <MDBox mr={2}>
              <MDButton
                onClick={handleClose}
                autoFocus
                variant="outlined"
                color="primary"
                size="small"
              >
                Cancel
              </MDButton>
            </MDBox>
            <MDButton
              autoFocus
              variant="gradient"
              color="primary"
              onClick={() => {
                if (deleteType === 0) {
                  deleteAnnouncements();
                } else {
                  deleteBanner();
                }
              }}
              size="small"
            >
              Confirm
            </MDButton>
          </MDBox>
        </Dialog>
      </MDBox>
      <Footer />
      <Snackbar
        open={openTwo}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          Removing item failed!
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default Dashboard;
